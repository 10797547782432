import React, { useState, useRef, useEffect } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
// import './App.css';
import Navigation from './Navigation';
import Home from './Home';
import Solutions from './Solutions';
import About from './AboutUs';
import Partners from './Partners';
import Testimonials from './Testimonials';
import CTA from './CTA';
import Footer from './Footer';
import Blog from './Blog';
import PoweredByVeverse from './PoweredByVeverse';
import VeScapesLayout from './VeScapesLayout';
import ArtheonLayout from './ArtheonLayout';
import Landing from './Landing';

function App() {
  const [currentLayout, setCurrentLayout] = useState('default');
  const [scrollToSection, setScrollToSection] = useState(null);
  const homeRef = useRef(null);
  const solutionsRef = useRef(null);
  const aboutRef = useRef(null);
  const partnersRef = useRef(null);
  const testimonialsRef = useRef(null);
  const ctaRef = useRef(null);

  const sectionRefs = {
    'home': homeRef,
    'solutions': solutionsRef,
    'about': aboutRef,
    'partners': partnersRef,
    'testimonials': testimonialsRef,
    'cta': ctaRef
  };

  const changeLayout = (layout, sectionId) => {
    setCurrentLayout(layout);
    if (layout === 'artheon') {
      // Scroll to the top if switching to ArtheonLayout
      window.scrollTo(0, 112);
    } 
    if (layout === 'vescapes') {
      // Scroll to the top if switching to ArtheonLayout
      window.scrollTo(0, 112);
    } else if (layout === 'default' && sectionId) {
      setScrollToSection(sectionId);
    }
  };

  useEffect(() => {
    if (scrollToSection && sectionRefs[scrollToSection]?.current) {
      sectionRefs[scrollToSection].current.scrollIntoView({ behavior: 'smooth' });
      setScrollToSection(null);
    }
  }, [scrollToSection, sectionRefs]);


  const renderLayout = () => {
    switch (currentLayout) {
      case 'vescapes':
        return <VeScapesLayout />;
      case 'artheon':
        return <ArtheonLayout />;
      default:
        return (
          <>
            <div id="home-section" ref={homeRef}><Home /></div>
            <div id="solutions-section" ref={solutionsRef}><Solutions /></div>
            <div id="about-section" ref={aboutRef}><About /></div>
            <div id="partners-section" ref={partnersRef}><Partners /></div>
            <div id="testimonials-section" ref={testimonialsRef}><PoweredByVeverse changeLayout={changeLayout} /> {/* Passing the callback */}</div>
            <div id="cta-section" ref={ctaRef}><CTA /></div>
          </>
        );
    }
  };

  return (
    <Landing />
  );
}

export default App;